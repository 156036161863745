import React from 'react';
import { PageHero, List, Link, PageWithSubNav, Paragraph, Section, SectionSubhead } from 'docComponents';
import pageHeroData from '../../../../data/pages/words.yml';

const IndexPage = () => {
  return (
    <PageWithSubNav pageType="design" title="Your, My, Neutral" subnav="words">
      <PageHero heroData={pageHeroData} tierTwo="Writing Guidelines" tierThree="Your, My, Neutral" />
      <Section>
        <Paragraph>
          Read John Saito’s{' '}
          <Link href="https://medium.com/@jsaito/is-this-my-interface-or-yours-b09a7a795256">
            excellent post for a summary of the issue
          </Link>
          .
        </Paragraph>
        <Paragraph>
          Hudl’s stance on how to orient a UI toward users’ content, features, etc., goes like this:
        </Paragraph>
        <List type="unordered">
          <li>
            <strong>By default, remain neutral</strong> (i.e., don’t say “your” or “my” or use any possessive pronoun)
            if possible. This should work at least 80% of the time.
          </li>
          <li>
            If some form of pronoun is required based on evidence (e.g., convincing qual/quant data), use “your” (e.g.,
            Your Playbook). This should cover you another 19% of the time.
          </li>
          <li>
            {/* eslint-disable max-len */}
            If the previous options fail to provide adequate clarity, “my” could be considered, but consult Design Ops
            before moving forward.
            {/* eslint-enable max-len */}
          </li>
        </List>
      </Section>
      <Section>
        <SectionSubhead>This, That, These and Those</SectionSubhead>
        <Paragraph>
          These/those are the plural forms of this/that and behave in the same way. <em>This</em> is used to identify a
          nearby person, thing or experience. <em>That</em> refers to the more distant of two things or a specific thing
          previously mentioned.
        </Paragraph>
        <Paragraph>
          If you need to refer to an element <strong>on the same screen</strong>, use “this” and “these” (e.g., “No
          results found for these filters.”).
        </Paragraph>
      </Section>
    </PageWithSubNav>
  );
};

export default IndexPage;
